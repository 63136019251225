import {useState, useEffect} from 'react';
import {
	RootContainer,
	Header,
	AddStreetContainer,
	FamilyMemberTitle,
	FamilyMemberHorizontalContainer,
	FamilyMemberContainer,
	InputLabel,
	SectionLabel,
	MembersContainer,
} from './AddFamily.styles';
import { useTranslation } from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {
	VerticalSpace,
	HorizontalSpace, 
	SecondaryButton, 
	UnderlinedButton, 
	TextInput, 
	CodeInput, 
	TextInputWithLoader, 
	Dropdown,
	LanguageBar,
	Family,
	Modal,
	SizedTextInput,
} from 'components';
import useGeolocation from 'react-hook-geolocation'
import moment from 'moment';
import Switch from 'react-switch';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {surveyActions} from 'modules/survey';

const initialMemberList = {
	name: '',
	mobile: '',
	gender: false,
	day: '',
	month: '',
	year: '',
	bloodGroup: '',
	proofType: '',
	proofId: '',
}


const AddFamily = () => {
	const {t, i18n} = useTranslation()
	const navigate = useNavigate()
	const geolocation = useGeolocation()
	const dispatch = useDispatch();
	const {user} = useSelector(state => state.auth, shallowEqual)
	const {villages, families} = useSelector(state => state.survey, shallowEqual)
	const [doorNumber, setDoorNumber] = useState({});
	const [selectedVillage, setSelectedVillage] = useState({});
	const [selectedStreet, setSelectedStreet] = useState({});
	const [showNewStreetModal, setShowNewStreetModal] = useState(false);
	const [currentAddedMembers, setCurrentAddedMembers] = useState([{...initialMemberList}]);
	const [newStreetName, setNewStreetName] = useState('');
	const [streets, setStreets] = useState({});

	const bloodGroupsArray = [{name: 'O+', value: 'O+'}, {name: 'O-', value: 'O-'}, {name: 'A+', value: 'A+'}, {name: 'B+', value: 'B+'}, {name: 'A-', value: 'A-'}, {name: 'B-', value: 'B-'}, {name: 'AB-', value: 'AB-'}, {name: 'AB+', value: 'AB+'}];
	const proofsArray = [{name: t('survey.aadhar'), value: 'aadhaar'}, {name: t('survey.voterId'), value: 'voterid'}, {name: t('survey.rationCard'), value: 'rationcard'}, {name: t('survey.drivinglisence'), value: 'drivinglisence'}, {name: t('survey.other'), value: 'other'}];

	const createNewStreet = () => {
		const requestObject = {name: newStreetName, village: selectedVillage._id}
		dispatch(surveyActions.createNewStreetAsyncActions.request(requestObject))
		setShowNewStreetModal(false)
	}

	const onInputValueChange = (index, key, value) => {
		const newArray = [...currentAddedMembers]
		newArray[index][key] = value

		setCurrentAddedMembers(newArray)
	}

	const addNewMember = () => {
		const newArray = [...currentAddedMembers, {...initialMemberList}]
		setCurrentAddedMembers(newArray)
	}

	const onSubmit = () => {
		const membersArray = currentAddedMembers.map(member => {
			const dob = moment(new Date(parseInt(member.year), parseInt(member.month)-1, parseInt(member.day)))
			const gender = member.gender?'female':'male'
			const mobile = `91${member.mobile}`
			const pastIllness = {
				hyperTension: member.pastHypertension,
				gdm: member.pastGdm
			}
			const familyHistory = {
				diabettis: member.familyDiabettis
			}
			if(!member.name && !member.proofId && !member.mobile && !member.proofType) {
				return null;
			}
			return {...member, village: selectedVillage._id, dob, gender, mobile, pastIllness, familyHistory, associatedPrimaryCentre: user.associatedInstitution._id, associatedCommunityCentre: user.associatedInstitution.associatedCommunityCentre, associatedUnitDistrict: user.associatedInstitution.associatedUnitDistrict, associatedUnitDistrict: user.associatedInstitution.associatedUnitDistrict, associatedDistrict: user.associatedInstitution.associatedDistrict}
		}).filter(member => member !== null && member !== undefined && member !== {})

		const reqBody = {
			streetName: selectedStreet._id,
			village: selectedVillage._id,
			associatedPrimaryCentre: user.associatedInstitution._id,
			associatedCommunityCentre: user.associatedInstitution.associatedCommunityCentre,
			associatedUnitDistrict: user.associatedInstitution.associatedUnitDistrict,
			associatedUnitDistrict: user.associatedInstitution.associatedUnitDistrict,
			associatedDistrict: user.associatedInstitution.associatedDistrict,
			doorNumber,
			mobile: membersArray[0].mobile,
			geolocation: {
				lat: geolocation.latitude,
				long: geolocation.longitude
			},
			members: membersArray,
			callback: () => window.open('/survey', '_self')
		}

		  dispatch(surveyActions.createNewFamilyAsyncActions.request(reqBody))
	}

	useEffect(() => {
		 dispatch(surveyActions.getVillagesAsyncActions.request({id: user.associatedInstitution._id}))
	}, [])

	return (
		<RootContainer>
			<LanguageBar />
			<VerticalSpace count={2} />
			<Header>
				<Dropdown items={villages} diplayProperty='displayName' showList={true} valueProperty='_id' seniority={700} onChange={setSelectedVillage} title={t('survey.villagesDropdownTitle')} />
				<VerticalSpace count={3} />
				<Dropdown items={selectedVillage?.streets || []} showList={selectedVillage._id} showListButton={true} seniority={600} onListButtonClick={() => setShowNewStreetModal(true)} hideListMessage='survey.selectVillage' diplayProperty='displayName' valueProperty='_id' onChange={setSelectedStreet} title={t('survey.streetsDropdownTitle')} />
				<VerticalSpace count={3} />
				<TextInput placeholder={t('survey.doorNumber')} onChange={evt => setDoorNumber(evt.target.value)} />
			</Header>

			<VerticalSpace count={3} />
			
			<MembersContainer>
				<FamilyMemberTitle>{t('survey.familyMembers')}</FamilyMemberTitle>
				{
					currentAddedMembers && currentAddedMembers.length
					?
					currentAddedMembers.map((member, i) => {
						// console.log("NEW ELEMENT", i, member)
						return (
							<FamilyMemberContainer>
							<VerticalSpace count={3} />
								<FamilyMemberHorizontalContainer>
									<SizedTextInput width='50%' placeholder={t('survey.name')} onChange={evt => onInputValueChange(i, 'name', evt.target.value)} />
									<HorizontalSpace count={2} />
									<FamilyMemberHorizontalContainer>
										<FamilyMemberTitle>{t('survey.maleShort')}</FamilyMemberTitle>
										<HorizontalSpace count={2} />
										<Switch onChange={value => onInputValueChange(i, 'gender', value)} checked={member.gender} uncheckedIcon={false} checkedIcon={false} />
										<HorizontalSpace count={2} />
										<FamilyMemberTitle>{t('survey.femaleShort')}</FamilyMemberTitle>
									</FamilyMemberHorizontalContainer>
								</FamilyMemberHorizontalContainer>
								<VerticalSpace count={2} />
								<FamilyMemberHorizontalContainer>
									<SizedTextInput width='50%' placeholder={t('auth.emailPlaceHolder')} onChange={evt => onInputValueChange(i, 'mobile', evt.target.value)} />
									<HorizontalSpace count={2} />
									<Dropdown items={bloodGroupsArray} diplayProperty='name' valueProperty='value' seniority={200} onChange={selected => onInputValueChange(i, 'bloodGroup', selected.value)} title={t('survey.bloodGroup')} showList={true} />
								</FamilyMemberHorizontalContainer>
								<VerticalSpace count={2} />
								<FamilyMemberHorizontalContainer>
									<Dropdown items={proofsArray} diplayProperty='name' valueProperty='value' seniority={100} onChange={selected => onInputValueChange(i, 'proofType', selected.value)} title={t('survey.proofType')} showList={true} />
									<HorizontalSpace count={2} />
									<SizedTextInput width='50%' placeholder={t('survey.proofId')} onChange={evt => onInputValueChange(i, 'proofId', evt.target.value)} />
								</FamilyMemberHorizontalContainer>
								<VerticalSpace count={3} />
								<InputLabel>{t('survey.dateOfBirth')}</InputLabel>
								<VerticalSpace count={1} />
								<FamilyMemberHorizontalContainer>
									<SizedTextInput width='17%' placeholder={t('survey.day')} onChange={evt => onInputValueChange(i, 'day', evt.target.value)} />
									<HorizontalSpace count={1} />
									<SizedTextInput width='17%' placeholder={t('survey.month')} onChange={evt => onInputValueChange(i, 'month', evt.target.value)} />
									<HorizontalSpace count={1} />
									<SizedTextInput width='30%' placeholder={t('survey.year')} onChange={evt => onInputValueChange(i, 'year', evt.target.value)} />
								</FamilyMemberHorizontalContainer>
								<VerticalSpace count={3} />
								<SectionLabel>{t('survey.pastIllness')}</SectionLabel>
								<VerticalSpace count={1} />
								<FamilyMemberHorizontalContainer>
									<FamilyMemberHorizontalContainer>
										<InputLabel>{t('survey.hypertension')}</InputLabel>
										<HorizontalSpace count={2} />
										<Switch onChange={value => onInputValueChange(i, 'pastHypertension', value)} checked={member.pastHypertension} uncheckedIcon={false} checkedIcon={false} height={20} width={40} />
									</FamilyMemberHorizontalContainer>
									<HorizontalSpace count={4} />
									{
										member.gender
										?
										<FamilyMemberHorizontalContainer>
											<InputLabel>{t('survey.gdm')}</InputLabel>
											<HorizontalSpace count={2} />
											<Switch onChange={value => onInputValueChange(i, 'pastGdm', value)} checked={member.pastGdm} uncheckedIcon={false} checkedIcon={false} height={20} width={40} />
										</FamilyMemberHorizontalContainer>
										:
										null
									}
								</FamilyMemberHorizontalContainer>
								<VerticalSpace count={3} />
								<SectionLabel>{t('survey.familyHistory')}</SectionLabel>
								<VerticalSpace count={1} />
								<FamilyMemberHorizontalContainer>
									<FamilyMemberHorizontalContainer>
										<InputLabel>{t('survey.diabettis')}</InputLabel>
										<HorizontalSpace count={2} />
										<Switch onChange={value => onInputValueChange(i, 'familyDiabettis', value)} checked={member.familyDiabettis} uncheckedIcon={false} checkedIcon={false} height={20} width={40} />
									</FamilyMemberHorizontalContainer>
									<HorizontalSpace count={4} />
								</FamilyMemberHorizontalContainer>
							</FamilyMemberContainer>
						)
					})
					:
					null
				}
				<VerticalSpace count={3} />
				<SecondaryButton onClick={addNewMember} >{t('survey.addAnotherMember')}</SecondaryButton>
			</MembersContainer>

			<VerticalSpace count={2} />
			<SecondaryButton background='green' onClick={onSubmit} ><strong>{t('survey.saveNewFamily')}</strong></SecondaryButton>
			<VerticalSpace count={5} />
			<Modal isVisible={showNewStreetModal} close={() => setShowNewStreetModal(false)}  >
				<AddStreetContainer>
					<VerticalSpace count={3} />
					<TextInput placeholder="Street Name" onChange={evt => setNewStreetName(evt.target.value)} />
					<VerticalSpace count={5} />
					<SecondaryButton onClick={createNewStreet} >Save</SecondaryButton>
				</AddStreetContainer>
			</Modal>
		</RootContainer>
	)
}

export default AddFamily;