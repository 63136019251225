import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {BsChevronDown, BsChevronUp} from 'react-icons/bs';

const AbsoluteContainer = styled.div`
  position: relative;
  z-index: ${({ seniority }) => 100+seniority};
`;

const RootContainer = styled.div`
	position: relative;
	background-color: ${({theme, background}) => background?theme.colors[background]:theme.colors.baseBackground};
	border: 1px solid #ccc;
	border-radius: 8px;
	cursor: pointer;
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DropdownHeading = styled.div`
  flex: 1;
  padding: 10px 20px;
  white-space: nowrap;
  font-size: ${({ theme }) => theme.font.sizes.subHeading};
  line-height: ${({theme}) => theme.font.lineHeight(theme.font.sizes.subHeading)};
  color: ${({theme}) => theme.colors.white};
`;

const SelectedItem = styled(DropdownHeading)`
  font-size: ${({ theme }) => theme.font.sizes.subHeading};
`;

const ListContainer = styled.div`
  position: absolute;
  z-index: -1; 
  width: 100%;
  top: calc(100% - 25px);
	max-height: 250px;
	background-color: ${({theme}) => theme.colors.baseBackground};
	padding-top: ${({theme, isOpen}) => isOpen?25:0}px;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	overflow-y: scroll;
	&:-webkit-scrollbar {
	  display: none;
	}

	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;
`;

const ListItem = styled.div`
  margin: ${({ theme, focused }) =>
    focused ? 10 : 0}px;
  padding: ${({ theme, focused }) => focused?`10px 0px`:'0px'};
  transition: all 0.3s ease;
  overflow: hidden;
  height: ${({ focused }) => (focused ? 'auto' : '0px')};
  font-size: ${({ theme }) => theme.font.sizes.normal};
  line-height: ${({ theme }) => theme.font.lineHeight(theme.font.sizes.normal)};
  color: ${({theme}) => theme.colors.white};
`;

const ListButton = styled(ListItem)`
	cursor: pointer;
	text-decoration: underline;
	font-weight: ${({ theme }) => theme.font.weight.bold};
	text-align: center;
`;

export default ({icon, iconBackground, title, diplayProperty, valueProperty, mandatory, items, showListButton, onListButtonClick, onChange, value, showList, hideListMessage, size, seniority, background}) => {
	const {t, i18n} = useTranslation()
	const [isOpen, setIsOpen] = useState(false);
	const [selected, setSelected] = useState({});

	const onSeleted = (item) => {
		onChange(item)
		setSelected(item);
		setIsOpen(false);
	}

	useEffect(() => {
		const currentItem = items?.filter(item => item[diplayProperty] === value || item[valueProperty] === value)
		setSelected(currentItem[0] || {})
	}, [value])

	// console.log("HIDE ITEMS", showList)
	
	return (
		<AbsoluteContainer seniority={seniority || 0} >	
			<RootContainer size={size} background={background} >
				<ListContainer isOpen={isOpen} >
					{
						!showList
						?
						<ListItem focused={isOpen} >{t(hideListMessage)}</ListItem>
						:
						items && items.length
						?
						<>
							{
								items.map(item => <ListItem onClick={() => onSeleted(item)} focused={isOpen} >{item[diplayProperty]}</ListItem>)
							}
							{
								showListButton
								?
								<ListButton focused={isOpen} onClick={onListButtonClick} >{t('survey.createNew')}</ListButton>
								:
								null
							}
						</>
						:
						<ListItem focused={isOpen} >{t('error.nothingToShow')}</ListItem>
					}
				</ListContainer>
				<HeadingContainer onClick={() => setIsOpen(!isOpen)} >
					{
						selected && (selected[diplayProperty] || selected[diplayProperty] === 0 || selected[valueProperty])
						?
						<SelectedItem>{selected[diplayProperty]}</SelectedItem>
						:
						<DropdownHeading>{title || ''}</DropdownHeading>
					}
					<BsChevronDown color="white" style={{marginRight: '5px'}} />
				</HeadingContainer>
			</RootContainer>
		</AbsoluteContainer>
	)
}
