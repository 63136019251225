import styled from 'styled-components';
import {useTransition, config as useTransitionConfig, animated} from 'react-spring';

const RootContainer = styled(animated.div)`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 5000;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0,0,0,0.5);
	backdrop-filter: blur(5px);
	display: flex;
	justify-content: center;
	align-items: center;
`;

const ContentContainer = styled.div`
	position: relative;
	width: 90%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const CloseButton = styled.div`
	position: absolute;
	top: -40px;
	right: 20px;
	padding: 10px;
	z-index: 5100;
	color: ${({theme}) => theme.colors.white};
	font-weight: ${({theme}) => theme.font.weight.bold};
	font-size: ${({theme}) => theme.font.sizes.subHeading};

`;

const Modal = ({children, isVisible, close}) => {
	const searchByTransition = useTransition(isVisible, {
		initial: { opacity: 1, translateY: 0 },
		from: { opacity: 0, translateY: isVisible?-100:100},
	    enter: { opacity: 1, translateY: 0 },
	    leave: { opacity: 0, translateY: isVisible?100:-100},
	});
	return (
		<>
			{
				searchByTransition((styles, item) => 
					item
					?
					<RootContainer style={styles} >
						<ContentContainer>
							<CloseButton onClick={close} >X</CloseButton>
							{children}
						</ContentContainer>
					</RootContainer>
					:
					null
				)
			}
		</>
	)
}

export default Modal;