import {useState, useEffect, useMemo} from 'react';
import {
	RootContainer,
	MobileContainer,
	Header,
	Heading,
	LanguageSelector,
	LanguageText,
	ContainerTitle,
	BodyContainer,
	TextInputLabel,
	HeaderChoice,
	AddressSelectedContainer,
	AddressSelected,
	FamilyScroller,
	// Family,
	// FamilyImage,
	// FamilyInfoContainer,
	// FamilyName,
	// FamilyInfo
	AddFamilyBtn,
	AddStreetContainer,
} from './FieldSurvey.styles';
import {FamilyRecord} from 'screens/Family/Family-record';
import {Family} from 'screens/Family';
import { useTranslation } from 'react-i18next';
import {useNavigate, useLocation} from 'react-router-dom';
import {
	VerticalSpace,
	HorizontalSpace, 
	SecondaryButton, 
	UnderlinedButton, 
	TextInput, 
	CodeInput, 
	TextInputWithLoader, 
	Dropdown,
	LanguageBar,
	Modal,
} from 'components';
import Switch from 'react-switch';
import {toast} from 'react-toastify';
// import {useNavigate} from 'react-router';
// import {Family} from 'screens/Family';
import {authActions} from 'modules/auth';
// import { useTranslation } from 'react-i18next';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {useTransition, config as useTransitionConfig} from 'react-spring';
// import {VerticalSpace, HorizontalSpace, SecondaryButton, UnderlinedButton, TextInput, CodeInput, TextInputWithLoader} from 'components';
// import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {surveyActions} from 'modules/survey';


function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const FieldSurvey = () => {
	const query = useQuery();
	const queryFamily = query.get('family');
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {t, i18n} = useTranslation();
	const [mobile, setMobile] = useState('');
	const [streets, setStreets] = useState({});
	const [language, setLanguage] = useState(false);
	const [showNewStreetModal, setShowNewStreetModal] = useState(false);
	const [selectedStreet, setSelectedStreet] = useState({});
	const [newStreetName, setNewStreetName] = useState('');
	const [selectedVillage, setSelectedVillage] = useState({});
	const [selectedFamily, setSelectedFamily] = useState(null);
	const [showFamilyInfo, setShowFamilyInfo] = useState(null);

	const {villages, families} = useSelector(state => state.survey, shallowEqual)
	const {user} = useSelector(state => state.auth, shallowEqual)


	// const [selectedFamily, setSelectedFamily] = useState({});

	const [searchByAddress, setSearchByAddress] = useState(true);
	// const [families, showFamilies] = useState(false);

	const searchByTransition = useTransition(searchByAddress, {
		initial: { opacity: 1, translateX: 0 },
		from: { opacity: 0, translateX: searchByAddress?-100:100},
	    enter: { opacity: 1, translateX: 0 },
	    leave: { opacity: 0, translateX: searchByAddress?100:-100},
	    delay: 200,
	});

	const createNewStreet = () => {
		const requestObject = {name: newStreetName, village: selectedVillage._id}
		dispatch(surveyActions.createNewStreetAsyncActions.request(requestObject))
		setShowNewStreetModal(false)
	}

	useEffect(() => {
		if(mobile.length === 10) {
			  dispatch(surveyActions.getFamiliesByMobileAsyncActions.request({mobile: `91${mobile}`}))
		}
	}, [mobile])

	useEffect(() => {
		if(language) {
			i18n.changeLanguage('ta');
		}
		else {
			i18n.changeLanguage('en');
		}
	}, [language]);

	useEffect(() => {
		if (!queryFamily) {
			setShowFamilyInfo(null);
			setSelectedFamily(null);
		}
	}, [queryFamily, selectedFamily]);

	useEffect(() => {
		setSelectedVillage({})
		setSelectedStreet({})
		dispatch(surveyActions.getVillagesAsyncActions.request({id: user.associatedInstitution._id}))
	}, [])

	// console.log("FAMILIES", families)


	return (
		<RootContainer>
		{
			selectedFamily
			?
			<FamilyRecord family={selectedFamily} />
			:
			<>
			<LanguageBar language={language} setLanguage={setLanguage} />
			<VerticalSpace count={2} />
			<Header>
				<HeaderChoice selected={searchByAddress} onClick={() => setSearchByAddress(true)} >{t('survey.address')}</HeaderChoice>
				<HeaderChoice selected={!searchByAddress} onClick={() => setSearchByAddress(false)} >{t('survey.mobile')}</HeaderChoice>
				<VerticalSpace count={5} />
				{
				searchByTransition((styles, item) =>
				item
				?
				<AddressSelectedContainer style={styles}>
					<Dropdown items={villages} showList={true} diplayProperty='displayName' valueProperty='_id' seniority={200} onChange={setSelectedVillage} title={t('survey.villagesDropdownTitle')} />
					<VerticalSpace count={3} />
					<Dropdown items={selectedVillage?.streets || []} onListButtonClick={() => setShowNewStreetModal(true)} showList={selectedVillage._id} hideListMessage='survey.selectVillage' diplayProperty='displayName' showListButton={true} valueProperty='_id' onChange={setSelectedStreet} seniority={100} title={t('survey.streetsDropdownTitle')} value='windows' />
				</AddressSelectedContainer>
				:
				<AddressSelectedContainer style={styles}>
					<TextInputLabel onChange={evt => setMobile(evt.target.value)} value={mobile}  />
				</AddressSelectedContainer>
				)}
			</Header>
			<FamilyScroller>
			{
				searchByAddress
				?
				selectedStreet?.families?.filter(family => family.members.length).map(family => 
					<>
					<Family key={'family'+family._id} family={family} selected={showFamilyInfo} setShowFamilyInfo={setShowFamilyInfo} setSelectedFamily={setSelectedFamily} />
					</>
				)
				:
				families?.filter(family => family.members.length).map(family => 
					<>
					<Family key={'family'+family._id} family={family} selected={showFamilyInfo}  setShowFamilyInfo={setShowFamilyInfo} setSelectedFamily={setSelectedFamily} />
					</>
				)
			}
			<VerticalSpace count={10} />
			</FamilyScroller>
			<AddFamilyBtn onClick={() => navigate('/addFamily')} >{t('survey.addNewFamily')}</AddFamilyBtn>
			</>
			}
		}
		<Modal isVisible={showNewStreetModal} close={() => setShowNewStreetModal(false)}  >
			<AddStreetContainer>
				<VerticalSpace count={3} />
				<TextInput placeholder="Street Name" onChange={evt => setNewStreetName(evt.target.value)} />
				<VerticalSpace count={5} />
				<SecondaryButton onClick={createNewStreet} >Save</SecondaryButton>
			</AddStreetContainer>
		</Modal>
		</RootContainer>
	)
}

export default FieldSurvey;
