import styled from 'styled-components';
import ScaleLoader from 'react-spinners/ScaleLoader';

const InputContainer = styled.div`
	display: flex;
	align-items: center;
	border-radius: 5px;
	background: ${({theme}) => theme.colors.white};
	padding: 5px 10px;
`;

const Input = styled.input.attrs(({type}) => ({type: type || 'text'}))`
	background: transparent;
	border: none;
	outline: none;
`;

const InputWithLoader = ({loading, ...props}) => {
	return (
		<InputContainer>
			<Input {...props} />
			{
				loading
				?
				<ScaleLoader height={20} width={1} radius={2} margin={2} />
				:
				null
			}
		</InputContainer>
	)
}

export default InputWithLoader;