export default {
	translation: {	
		auth: {
			headerTitle: 'Tamil Nadu Public Health Registry',
			signinTitle: 'Sign In with OTP',
			pinTitle: 'Sign in With Passcode',
			emailPlaceHolder: 'Mobile Number*',
			otpPlaceHolder: 'Enter OTP',
			submitButton: 'Verify'
		},
		survey: {
			villagesDropdownTitle: "Village*",
			streetsDropdownTitle: "Street*",
			familyTitle: "'s Family",
			address: "Address",
			mobile: "Mobile",
			selectVillage: "Select a village to see the streets in it!",
			createNew: '+ Create New',
			familyMembers: "Family Members",
			addMemberSuccessful: "New member added successfully",
			allFieldsAreMandatory: "You need to fill all the fields to continue",
			doorNumber: "Door Number*",
			name: "Name*",
			age: "Age*",
			gender: "Gender",
			bloodGroup: "Blood Group*",
			proofType: "Proof Type*",
			proofId: "Proof ID*",
			mobile: "Mobile",
			maleShort: "M",
			femaleShort: "F",
			male: "Male",
			female: "Female",
			dateOfBirth: "Date of Birth*",
			day: "Day*",
			month: "Month*",
			year: "Year*",
			bloodPressure: "Blood Pressure*",
			systole: "Systolic",
			diastole: "Diastolic",
			sugar: "Random Blood Sugar*",
			aadhar: "Aadhar",
			voterId: "Voter Id",
			rationCard: "Ration Card",
			drivinglisence: "Driving License",
			other: "Other",
			addAnotherMember: "Add Another Member",
			saveNewFamily: "Save New Family",
			addNewFamily: "Add New Family",
			saveNewMember: "Save New Member",
			exit: "Exit",
			saveAndNext: "Save and Next",
			startSurvey: "Start Survey",
			addFamilyMember: "Add New Member",
			members: "Members",
			pastIllness: "Past Illness",
			hypertension: "Hypertension",
			familyHistory: "Family Health History",
			gdm: "GDM",
			diabettis: "Diabettis Mellitus",
			diagnosedWith: "Diagnosed With",
			years: "Years",
			medicinesGiven: "Medicines Delivered",
		},
		error: {
			somethingWentWrong: "Something went wrong",
			nothingToShow: "Nothing to show here",
			login: {
				userNotFound: "Unable to find the user for this Mobile Number. Please check with your associated PHC"
			},
			findFamily: {
				notfound: "Unable to find a Family linked to this Mobile Number"
			}
		},
		featureComingSoon: "This is in development. We will show this feature in the final project demo. Thank you!"
	}
}