import styled from 'styled-components';
import {
	SelectNothing
} from 'screens/screens.styles.js';

const PrimaryButton = styled(SelectNothing)`
	padding: 10px 20px;
	color: ${({theme, color, disabled}) => disabled?theme.colors.disabledBackground:color?theme.colors[color]:theme.colors.white};
	background-color: ${({theme, background, disabled}) => disabled?theme.colors.disabledText:background?theme.colors[background]:theme.colors.blue};
	font-size: ${({theme}) => theme.font.sizes.heading};
	line-height: ${({theme}) => theme.font.lineHeight(theme.font.sizes.heading)};
	font-weight: ${({theme}) => theme.font.weight.bold};
	cursor: pointer;
	transition: all 0.3s ease;
	border-radius: 10px;

	&:active {
		opacity: 0.7;
	}
`;

export const SecondaryButton = styled(PrimaryButton)`
	padding: 7px 13px;
	font-size: ${({theme}) => theme.font.sizes.normal};
	line-height: ${({theme}) => theme.font.lineHeight(theme.font.sizes.normal)};
	font-weight: ${({theme}) => theme.font.weight.normal};
	border-radius: 5px;
	text-align: center;
`;

export const UnderlinedButton = styled.div`
	font-size: ${({theme}) => theme.font.sizes.normal};
	line-height: ${({theme}) => theme.font.lineHeight(theme.font.sizes.normal)};
	color: ${({theme}) => theme.colors.blue};
	text-decoration: underline;
`;