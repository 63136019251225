import {
	searchWithMobileAsyncActions,
	loginAsyncActions
} from './actions';

const initialState = {
	families: [],
	villages: [],
	user: {},
	isAuthenticated: false,
	isLoading: false,
	isError: false,
	error: '',
}

export default (state = initialState, action) => {
	switch(action.type) {
		case searchWithMobileAsyncActions.actionNames.request:
			return {...state, isLoading: true, families: []}
		case searchWithMobileAsyncActions.actionNames.success:
			return {...state, isLoading: false, families: action.payload}
		case searchWithMobileAsyncActions.actionNames.error:
			return {...state, isLoading: false, isError: true, error: action.payload, families: []}

		case loginAsyncActions.actionNames.request:
			return {...state, isLoading: true, isAuthenticated: false}
		case loginAsyncActions.actionNames.success:
			return {...state, isLoading: false, isAuthenticated: true, user: action.payload}
		case loginAsyncActions.actionNames.error:
			return {...state, isLoading: false, isError: true, error: action.payload, isAuthenticated: false}
		default:
			return state
	}
}