import styled from 'styled-components';
import {animated} from 'react-spring';
import {SecondaryButton} from 'components';

export const RootContainer = styled.div`
	position: relative;
	padding: 10px;
	width: 100vw;
	height: 100vh;
	background-color: ${({theme}) => theme.colors.baseBackground};
	box-sizing: border-box;
`;

export const Header = styled.div`
	position: relative;
	height: auto;
	padding: 1rem 20px;
	font-size: ${({theme}) => theme.font.sizes.heading};
	border-radius: 10px;
	border: none;
	outline: none;
	background: ${({theme}) => theme.colors.containerBackground};
`;

export const MembersContainer = styled(Header)`
	max-height: 60vh;
	overflow-y: auto;
`;

export const AddStreetContainer = styled(Header)`
	width: 75%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const AddressSelectedContainer = styled(animated.div)`
	position: absolute;
	width: calc(100% - 40px);

`;
export const AddressSelected = styled.div`
	width: 100%;
	height: 35px;
	border: none;
	margin: 10px 0;
	margin-top: 20px;
	padding: 10px 20px;
	border-radius: 10px;
	outline: none;
	overflow: hidden;
	color: white;
	background: ${({theme}) => theme.colors.baseBackground};
	box-sizing: border-box;
`;

export const FamilyMemberTitle = styled.div`
	color: ${({theme}) => theme.colors.white};
	font-size: ${({theme}) => theme.font.sizes.subHeading};
	font-weight: ${({theme}) => theme.font.weight.bold};
`;

export const SectionLabel = styled(FamilyMemberTitle)`
	font-size: ${({theme}) => theme.font.sizes.subHeading};
`;

export const InputLabel = styled(FamilyMemberTitle)`
	font-size: ${({theme}) => theme.font.sizes.normal};
`;

export const FamilyMemberHorizontalContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
`;

export const FamilyMemberContainer = styled.div`
	padding-bottom: 30px;
	border-bottom: 1px solid #777;
`;