import 'utilities/firebaseConfig';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import {ThemeProvider} from 'styled-components'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
 import createStore from "./store";
 // import apiDefaults from "utilities/apiDefaults";
import theme from 'theme';
import Router from 'navigation';
import 'utilities/translator';
import 'utilities/apiDefaults';
import 'utilities/tweaks';

 const { store, persistor } = createStore();
 // apiDefaults();

function App() {

  return (
     <Provider store={store} >
      <PersistGate persistor={persistor} >
        <ThemeProvider theme={theme} >
          <Router />
          <ToastContainer />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
