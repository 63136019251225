import {useState, useEffect} from 'react';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {HorizontalSpace} from 'components';
import Switch from 'react-switch';
import {AiOutlineLeft} from 'react-icons/ai';

const RootContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: calc(100% - 40px);
	background-color: ${({theme}) => theme.colors.containerBackground};
	padding: 10px 20px;
`;
const LanguageSelector = styled.div`
	display: flex;
	align-items: center;
	padding: 5px;
`;

const LanguageText = styled.span`
	font-size: ${({theme}) => theme.font.sizes.normal};
	line-height: ${({theme}) => theme.font.lineHeight(theme.font.sizes.normal)};
	font-weight: ${({theme}) => theme.font.weight.bold};
	color: ${({theme, selected}) => selected?theme.colors.white:theme.colors.lightGrey};
`;

const LanguageBar = () => {
	const {t, i18n} = useTranslation()
	const navigate = useNavigate()
	const [language, setLanguage] = useState(false);

	useEffect(() => {
			if(language) {
				i18n.changeLanguage('ta');
			}
			else {
				i18n.changeLanguage('en');
			}
		}, [language])

	return (
		<RootContainer>
			<AiOutlineLeft color="white" size={20} onClick={() => navigate(-1)} />
			<LanguageSelector>
				<LanguageText selected={language === false} >En</LanguageText>
				<HorizontalSpace count={1} />
				<Switch onChange={setLanguage} checked={language} uncheckedIcon={false} checkedIcon={false} height={20} width={40} />
				<HorizontalSpace count={1} />
				<LanguageText selected={language === true} >த</LanguageText>
			</LanguageSelector>
		</RootContainer>
	);
}

export default LanguageBar;