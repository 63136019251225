import axios from 'axios';

export const getVillages = (id) => {
	return axios.get(`/survey/villages/${id}`)
}

export const getFamilies = (id) => {
	return axios.get(`/survey/families/street/${id}`)
}

export const getFamiliesByMobile = (mobile) => {
	return axios.get(`/survey/families/mobile/${mobile}`)
}

export const createNewStreet = (data) => {
	return axios.post(`/survey/street/new`, data)
}

export const createNewFamily = (data) => {
	return axios.post(`/survey/family/new`, data)
}

export const addNewMember = (data) => {
	return axios.post('survey/individual/new', data)
}

export const saveRecord = (data) => {
	return axios.post('/survey/create', data)
}