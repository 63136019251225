import styled from 'styled-components';

export const Flex = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const SelectNothing = styled.div`
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-drag: none;
    cursor: default;
`;
export const SelectNothingSpan = styled.span`
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-drag: none;
    cursor: default;
`;