import {useState, useEffect, useMemo} from 'react';
import {
	RootContainer,
	Header,
	AddStreetContainer,
	FamilyMemberTitle,
	FamilyMemberHorizontalContainer,
	FamilyMemberContainer,
	InputLabel,
	SectionLabel,
	MembersContainer,
} from './AddFamily.styles';
import { useTranslation } from 'react-i18next';
import {useNavigate, useLocation} from 'react-router-dom';
import {
	VerticalSpace,
	HorizontalSpace, 
	SecondaryButton, 
	UnderlinedButton, 
	TextInput, 
	CodeInput, 
	TextInputWithLoader, 
	Dropdown,
	LanguageBar,
	Family,
	Modal,
	SizedTextInput,
} from 'components';
import useGeolocation from 'react-hook-geolocation';
import moment from 'moment';
import Switch from 'react-switch';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {surveyActions} from 'modules/survey';
import {toast} from 'react-toastify';

const initialMemberList = {
	name: '',
	mobile: '',
	gender: false,
	day: '',
	month: '',
	year: '',
	bloodGroup: '',
	proofType: '',
	proofId: '',
}

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}


export const AddMember = () => {
	const query = useQuery();
	const navigate = useNavigate();
	const {t, i18n} = useTranslation();
	const geolocation = useGeolocation();
	const queryFamily = query.get('family');

	const dispatch = useDispatch();
	const {user} = useSelector(state => state.auth, shallowEqual)
	const {villages, families} = useSelector(state => state.survey, shallowEqual)

	const [name, setName] = useState('');
	const [gender, setGender] = useState(false);
	const [mobile, setMobile] = useState('');
	const [day, setDay] = useState(null);
	const [month, setMonth] = useState(null);
	const [year, setYear] = useState(null);
	const [bloodGroup, setBloodGroup] = useState('');
	const [proofType, setProofType] = useState('');
	const [proofId, setProofId] = useState('');
	const [pastHypertension, setPastHypertension] = useState(false);
	const [pastGdm, setPastGdm] = useState(false);
	const [familyDiabettis, setFamilyDiabettis] = useState(false);

	const bloodGroupsArray = [{name: 'O+', value: 'O+'}, {name: 'O-', value: 'O-'}, {name: 'A+', value: 'A+'}, {name: 'B+', value: 'B+'}, {name: 'A-', value: 'A-'}, {name: 'B-', value: 'B-'}, {name: 'AB-', value: 'AB-'}, {name: 'AB+', value: 'AB+'}];
	const proofsArray = [{name: t('survey.aadhar'), value: 'aadhaar'}, {name: t('survey.voterId'), value: 'voterid'}, {name: t('survey.rationCard'), value: 'rationcard'}, {name: t('survey.drivinglisence'), value: 'drivinglisence'}, {name: t('survey.other'), value: 'other'}];

	const onSubmit = () => {
		const dob = moment().set({year, month: month-1, date: day}).format();

		if(!name || !mobile || !bloodGroup || !proofType || !proofId || !dob) {
			toast.success(t('survey.allFieldsAreMandatory'))
			return null;
		}

		const requestBody = {
			name,
			gender: gender?'female':'male',
			mobile: '91'+mobile,
			bloodGroup,
			proofType,
			proofId,
			dob,
			pastIllness: {
				hypertension: pastHypertension,
				gdm: pastGdm
			},
			familyHistory: {
				diabettis: familyDiabettis
			},
			familyId: queryFamily
		}

		const callBackFn = () => {
			toast.success(t('survey.addMemberSuccessful'))
			setTimeout(() => navigate('/survey'), 2000)
		}

		dispatch(surveyActions.addNewMemberAsyncActions.request({...requestBody, callback: callBackFn}))
	}

	return (
		<RootContainer>
			<LanguageBar />
			<VerticalSpace count={2} />
			
			<MembersContainer>
				<FamilyMemberTitle>{t('survey.familyMembers')}</FamilyMemberTitle>
						<FamilyMemberContainer>
						<VerticalSpace count={3} />
							<FamilyMemberHorizontalContainer>
								<SizedTextInput width='50%' placeholder={t('survey.name')} onChange={evt => setName(evt.target.value)} />
								<HorizontalSpace count={2} />
								<FamilyMemberHorizontalContainer>
									<FamilyMemberTitle>{t('survey.maleShort')}</FamilyMemberTitle>
									<HorizontalSpace count={2} />
									<Switch onChange={value => setGender(value)} checked={gender} uncheckedIcon={false} checkedIcon={false} />
									<HorizontalSpace count={2} />
									<FamilyMemberTitle>{t('survey.femaleShort')}</FamilyMemberTitle>
								</FamilyMemberHorizontalContainer>
							</FamilyMemberHorizontalContainer>
							<VerticalSpace count={2} />
							<FamilyMemberHorizontalContainer>
								<SizedTextInput width='50%' placeholder={t('auth.emailPlaceHolder')} onChange={evt => setMobile(evt.target.value)} />
								<HorizontalSpace count={2} />
								<Dropdown items={bloodGroupsArray} diplayProperty='name' valueProperty='value' seniority={200} onChange={selected => setBloodGroup(selected.value)} title={t('survey.bloodGroup')} showList={true} />
							</FamilyMemberHorizontalContainer>
							<VerticalSpace count={2} />
							<FamilyMemberHorizontalContainer>
								<Dropdown items={proofsArray} diplayProperty='name' valueProperty='value' seniority={100} onChange={selected => setProofType(selected.value)} title={t('survey.proofType')} showList={true} />
								<HorizontalSpace count={2} />
								<SizedTextInput width='50%' placeholder={t('survey.proofId')} onChange={evt => setProofId(evt.target.value)} />
							</FamilyMemberHorizontalContainer>
							<VerticalSpace count={3} />
							<InputLabel>{t('survey.dateOfBirth')}</InputLabel>
							<VerticalSpace count={1} />
							<FamilyMemberHorizontalContainer>
								<SizedTextInput width='17%' placeholder={t('survey.day')} onChange={evt => setDay(evt.target.value)} />
								<HorizontalSpace count={1} />
								<SizedTextInput width='17%' placeholder={t('survey.month')} onChange={evt => setMonth(evt.target.value)} />
								<HorizontalSpace count={1} />
								<SizedTextInput width='30%' placeholder={t('survey.year')} onChange={evt => setYear(evt.target.value)} />
							</FamilyMemberHorizontalContainer>
							<VerticalSpace count={3} />
							<SectionLabel>{t('survey.pastIllness')}</SectionLabel>
							<VerticalSpace count={1} />
							<FamilyMemberHorizontalContainer>
								<FamilyMemberHorizontalContainer>
									<InputLabel>{t('survey.hypertension')}</InputLabel>
									<HorizontalSpace count={2} />
									<Switch onChange={value => setPastHypertension(value)} checked={pastHypertension} uncheckedIcon={false} checkedIcon={false} height={20} width={40} />
								</FamilyMemberHorizontalContainer>
								<HorizontalSpace count={4} />
								{
								gender
								?
								<FamilyMemberHorizontalContainer>
									<InputLabel>{t('survey.gdm')}</InputLabel>
									<HorizontalSpace count={2} />
									<Switch onChange={value => setPastGdm(value)} checked={pastGdm} uncheckedIcon={false} checkedIcon={false} height={20} width={40} />
								</FamilyMemberHorizontalContainer>
								:
								null
								}
							</FamilyMemberHorizontalContainer>
							<VerticalSpace count={3} />
							<SectionLabel>{t('survey.familyHistory')}</SectionLabel>
							<VerticalSpace count={1} />
							<FamilyMemberHorizontalContainer>
								<FamilyMemberHorizontalContainer>
									<InputLabel>{t('survey.diabettis')}</InputLabel>
									<HorizontalSpace count={2} />
									<Switch onChange={value => setFamilyDiabettis(value)} checked={familyDiabettis} uncheckedIcon={false} checkedIcon={false} height={20} width={40} />
								</FamilyMemberHorizontalContainer>
								<HorizontalSpace count={4} />
							</FamilyMemberHorizontalContainer>
						</FamilyMemberContainer>
				<VerticalSpace count={3} />
				{/*<SecondaryButton onClick={addNewMember} >{t('survey.addAnotherMember')}</SecondaryButton>*/}
			</MembersContainer>

			<VerticalSpace count={2} />
			<SecondaryButton background='green' onClick={onSubmit} ><strong>{t('survey.saveNewMember')}</strong></SecondaryButton>
			<VerticalSpace count={5} />
		</RootContainer>
	)
}

export default AddMember;
