import { useState, useEffect } from 'react';
import {
	SecondaryButton,
	LanguageBar,
} from 'components';
import {
	SurveyRootContainer,
	PatientContainer,
	PatientName,
	PatientInfo,
	PatientCard,
	Record,
	RecordName,
	RecordInputContainer,
	RecordInput,
	DoubleBtnContainer
} from './family.styles.js';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Switch from 'react-switch';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {surveyActions} from 'modules/survey';

const initialData = {
	systole: null,
	diastole: null,
	bloodSugar: null,
}

export const FamilyRecord = ({family}) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const [record, setRecord] = useState({
		systole: '',
		diastole: '',
		bloodSugar: '',
	});
	const [medicinesDelivered, setMedicinesDelivered] = useState(true)
	const auth = useSelector(state => state.auth)
	const [currentPatient, setCurrentPatient] = useState(0);
	const {members} = family;

	const setRecordData = (key, value) => {
		const existingData = {...record}
		existingData[key] = value || ''
		setRecord({...existingData})
	}

	const onSave = () => {
		const {_id, id, streetName, doorNumber, mobile, geolocation, createdOn, ...familyMetaData} = family;
		const reqBody = {
			...familyMetaData,
			takenAt: 'field',
			volunteerId: auth.user._id,
			associatedPrimaryCentre: auth.user.associatedInstitution._id,
			associatedDistrict: auth.user.associatedInstitution.associatedDistrict,
			associatedCommunityCentre: auth.user.associatedInstitution.associatedCommunityCentre,
			associatedUnitDistrict: auth.user.associatedInstitution.associatedUnitDistrict,
			individualId: members[currentPatient]._id,
			familyId: family._id,
			bloodSugarRandom: record.bloodSugar,
			bloodPressure: {
				systolic: record.systole,
				diastolic: record.diastole
			},

		}
		setRecord({
			systole: '',
			diastole: '',
			bloodSugar: '',
		})
		if (members[currentPatient+1]) {
			 dispatch(surveyActions.saveRecordAsyncActions.request({...reqBody, callback: () => setCurrentPatient(currentPatient+1)}))
			return null;
		}
		 dispatch(surveyActions.saveRecordAsyncActions.request({...reqBody, callback: () => navigate(-1)}))
	}

	function capitalizeFirstLetter(string) {
	  return string.charAt(0).toUpperCase() + string.slice(1);
	}

	return(
		<SurveyRootContainer style={{background: 'transparent'}} >
		<LanguageBar />
		{
			members[currentPatient]
			?
			<>
				<PatientContainer>
					<PatientName>{members[currentPatient].name} (<a href={`tel:+${members[currentPatient].mobile}`}>{members[currentPatient].mobile}</a>)</PatientName>
					<PatientInfo>{t(`survey.${members[currentPatient].gender}`)}, {moment().diff(members[currentPatient].dob, 'years')} {t('survey.years')}</PatientInfo>
					<PatientInfo>{t('survey.diagnosedWith')} <strong>{members[currentPatient].lastDiagnosedWith.length?members[currentPatient].lastDiagnosedWith.join(', ').toUpperCase():'--'}</strong></PatientInfo>
				</PatientContainer>
				<PatientCard>
					<Record>
						<RecordName>{t('survey.bloodPressure')}</RecordName>
						<RecordInputContainer>
							<RecordInput value={record.systole} onChange={evt => setRecordData('systole', parseInt(evt.target.value))} type='tel' placeholder={t('survey.systole')} /> 
							Hg/mm
						</RecordInputContainer>
						<RecordInputContainer>
							<RecordInput value={record.diastole} onChange={evt => setRecordData('diastole', parseInt(evt.target.value))} type='tel' placeholder={t('survey.diastole')}/> 
							Hg/mm
						</RecordInputContainer>
					</Record>
					<Record>
						<RecordName>{t('survey.sugar')}</RecordName>
						<RecordInputContainer>
							<RecordInput value={record.bloodSugar} onChange={evt => setRecordData('bloodSugar', parseInt(evt.target.value))} style={{width: '80px'}} type='tel' placeholder={t('survey.sugar')} /> 
							mg/dl
						</RecordInputContainer>
					</Record>
					<Record>
							<RecordName>{t('survey.medicinesGiven')}</RecordName>
							<Switch onChange={setMedicinesDelivered} checked={medicinesDelivered} uncheckedIcon={false} checkedIcon={false} />
					</Record>
					<DoubleBtnContainer>
						<SecondaryButton onClick={() => navigate(-1)} >{t('survey.exit')}</SecondaryButton>
						<SecondaryButton onClick={onSave} >{t('survey.saveAndNext')}</SecondaryButton>
				</DoubleBtnContainer>
				</PatientCard>
			</>
			:
			null
		}
		</SurveyRootContainer>
	);
}