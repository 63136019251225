import {
	useTransition, useTrail, animated
} from 'react-spring';
import moment from 'moment';
import {
	RootContainer,
	FamilyContainer,
	FamilyImage,
	FamilyInfoContainer,
	FamilyName,
	FamilyInfo,
	FamilyMember,
	FamilyBtnsContainer,
} from './family.styles.js';
import {toast} from 'react-toastify';
import {BsChevronDown, BsChevronUp} from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import {
	SecondaryButton
} from 'components';
import {useNavigate} from 'react-router';

export const Family =  ({family, showFamilyInfo, setShowFamilyInfo, selected, setSelectedFamily}) => {
	const {t, i18n} = useTranslation();
	const navigate = useNavigate();
	// const members = new Array(family.members).fill(1);
	// console.log(family.id, selected)

	const FamilyMembersTransition = useTransition(selected === family.id?family.members:null, {
		from: { opacity: 0, y: -15 },
		enter: { opacity: 1, y: 0 },
		trail: 80,
	});
	// const FamilyMembersTransition = useTransition(family.members, {
	// 	from: { height: '0px', overflowY: 'hidden' },
	// 	enter: { height: '200px', overflowY: 'scroll' },
	// 	leave: { height: '0px', overflowY: 'hidden' },
	// });

	return (
		<RootContainer>
			<FamilyContainer onClick={() => setShowFamilyInfo(selected?null:family.id)} >
				<FamilyInfoContainer>
					<FamilyName>{family?.members[0]?.name}</FamilyName>
					{/*<FamilyInfo><a href="tel:{paste-number-here}">Click this</a></FamilyInfo>*/}
					<FamilyInfo>{family?.members.length} {t('survey.members')}</FamilyInfo>
				</FamilyInfoContainer>
				<BsChevronDown color="white" size={20} style={{marginRight: '5px', cursor: 'pointer'}} />
			</FamilyContainer>
			<FamilyBtnsContainer>
				<SecondaryButton onClick={() => {setSelectedFamily(family); navigate('/survey?family='+family.id)}} >{t('survey.startSurvey')}</SecondaryButton>
				<SecondaryButton onClick={() => {navigate('/addmember?family='+family.id)}} >{t('survey.addFamilyMember')}</SecondaryButton>
			</FamilyBtnsContainer>
			{
			FamilyMembersTransition((styles, item, state, i) => (
				item &&
				<animated.div key={`family-members-${i}`} style={styles}>
					<FamilyMember>
						<FamilyImage src={item.gender === 'male'?'https://cdn-icons-png.flaticon.com/512/0/93.png':'https://cdn-icons-png.flaticon.com/512/56/56863.png'} />	
						<FamilyInfoContainer>
							<FamilyName>{item.name}</FamilyName>
						</FamilyInfoContainer>
					</FamilyMember>
				</animated.div>
			))}
		</RootContainer>
	);
}