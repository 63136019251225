import styled from 'styled-components'

export const RootContainer = styled.div`
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	background-color: ${({theme}) => theme.colors.baseBackground};
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const MobileContainer = styled.div`
	width: 80%;
	margin: 10px;
	padding: 10px;
	border-radius: 10px;
	border: 1px solid ${({theme}) => theme.colors.lightGrey};
	background-color: ${({theme}) => theme.colors.containerBackground};
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const Heading = styled.div`
	font-size: ${({theme}) => theme.font.sizes.heading};
	line-height: ${({theme}) => theme.font.lineHeight(theme.font.sizes.heading)};
	font-weight: ${({theme}) => theme.font.weight.bold};
	color: ${({theme}) => theme.colors.white};
`;

export const LanguageSelector = styled.div`
	display: flex;
	align-items: center;
	padding: 5px;
`;

export const LanguageText = styled.span`
	font-size: ${({theme}) => theme.font.sizes.normal};
	line-height: ${({theme}) => theme.font.lineHeight(theme.font.sizes.normal)};
	font-weight: ${({theme}) => theme.font.weight.bold};
	color: ${({theme, selected}) => selected?theme.colors.white:theme.colors.lightGrey};
`;

export const ContainerTitle = styled.div`
	font-size: ${({theme}) => theme.font.sizes.heading};
	line-height: ${({theme}) => theme.font.lineHeight(theme.font.sizes.heading)};
	font-weight: ${({theme}) => theme.font.weight.normal};
	color: ${({theme}) => theme.colors.white};
`;

export const CaptchaContainer = styled.div`
	position: absolute;
	display: none;
	width: 0px;
	height: 0px;
	top: 0;
	left: 0;
`;