import styled from 'styled-components';
import {
	Header
} from 'screens/FieldSurvey/FieldSurvey.styles';

// export const FamilyContainer = styled.div`
// 	margin-top: 20px;
// 	height: calc(100vh - 250px);
// 	overflow-y: scroll;
// `;
export const RootContainer = styled.div`
	background: ${({theme}) => theme.colors.containerBackground};
	padding: 10px 20px;
`;

export const SurveyRootContainer = styled.div`
	background: ${({theme}) => theme.colors.containerBackground};
`;

export const FamilyContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	margin: 10px 0;
	border: none;
	border-radius: 10px;
	outline: none;
	overflow: hidden;
	box-sizing: border-box;
`;
export const FamilyImage = styled.img.attrs(props => ({
	src: props.src,
	alt: 'family-image'
}))`
	border: none;
	border-radius: 50%;
	height: 80px;
	width: 80px;
	margin-right: 10px;
`;
export const FamilyInfoContainer = styled.div`
	flex: 1;
	color: ${({theme}) => theme.colors.white};
`;
export const FamilyName = styled.div`
	font-size: ${({theme}) => theme.font.sizes.heading};
	font-weight: ${({theme}) => theme.font.weight.bold};
	color: ${({theme}) => theme.colors.white};
`;
export const FamilyInfo = styled.div`
	font-size: ${({theme}) => theme.font.sizes.subHeading};
	color: ${({theme}) => theme.colors.white};
	margin: 5px 0;
`;
export const FamilyMember = styled(FamilyContainer)`
	padding: 10px 20px;
	background: ${({theme}) => theme.colors.baseBackground};
`;
export const FamilyBtnsContainer = styled.div`
	width: 320px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	margin: 15px 0;

	@media only screen and (max-width: 450px)
	{
		width: 100%;
	}
`;



// ============================================
// family member record styles
// ============================================
export const PatientContainer = styled(Header)`
	height: 120px;
	border: none;
	border-radius: 10px;
	margin: 10px 20px;
	overflow: hidden;
`;
export const PatientName = styled(FamilyName)`
	& a {
		color: ${({theme}) => theme.colors.blue};
	}
`;
export const PatientInfo = styled(FamilyInfo)`
	margin: 10px 0;
`;
export const PatientCard = styled(RootContainer)`
	height: auto;
	margin: 20px 20px;
	padding: 10px;
`;
export const Record = styled.div`
	margin: 20px 10px;
`;
export const RecordName = styled(PatientName)`
	font-size: 	${({theme}) => theme.font.sizes.normal};
	margin: 10px 0;
`;
export const RecordInputContainer = styled.span`
	// width: 100px;
	display: inline-block;
	padding: 10px;
	margin: 5px;
	border-radius: 10px;
	color: ${({theme}) => theme.colors.white};
	font-size: 	${({theme}) => theme.font.sizes.normal};
	background: ${({theme}) => theme.colors.baseBackground};
`;
export const RecordInput = styled.input.attrs((props) => ({
	type: props.type,
	placeholder: props.placeholder,
	maxLength: 4
}))`
	width: 60px;
	color: ${({theme}) => theme.colors.white};
	font-size: 	${({theme}) => theme.font.sizes.normal};
	border: none;
	border-radius: 10px solid;
	outline: none;
	background: transparent;
`;
export const DoubleBtnContainer = styled(FamilyBtnsContainer)`
	
	margin-top: 40px;
	& div {
		width: 100px;
	}
`;