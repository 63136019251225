import styled from 'styled-components';
import SquareLoader from 'react-spinners/SquareLoader';

const FullScreenOverlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10000;
	display: flex;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0,0,0,0.5);
	justify-content: center;
	align-items: center;
`;

export const FullScreenLoading = () => {
	return(
		<FullScreenOverlay>
			<SquareLoader size={35} color="#0096C7" />
		</FullScreenOverlay>
	)
}