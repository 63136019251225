import {useState, useEffect} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Routes
} from "react-router-dom";
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {FullScreenLoading} from 'components';
import {
  Auth,
  FieldSurvey,
  AddFamily,
  AddMember
} from 'screens'

import styled from 'styled-components';

const InternetConnectivityLabel = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  background-color: ${({theme}) => theme.colors.red};
  color: ${({theme}) => theme.colors.white};
  overflow: hidden;
  max-height: ${({isVisible}) => isVisible?'50px':'0px'};
  transition: height 0.3s ease;
  text-align: center;
  z-index: 100;

   & div {
    margin: 10px 5px;
    text-align: center;
   }
`;

const RootRouter = () => {
  const auth = useSelector(state => state.auth, shallowEqual)
  const survey = useSelector(state => state.survey, shallowEqual)
  const [isOnline, setIsOnline] = useState(window.navigator.onLine)

  useEffect(() => {
    window.addEventListener('online', () => setIsOnline(true))
    window.addEventListener('offline', () => setIsOnline(false))
  }, [])

  return (
    <Router>
      <InternetConnectivityLabel isVisible={!isOnline} >
        <div>No Internet!</div>
      </InternetConnectivityLabel>
      <Routes>
        <Route path="/" element={<Auth />} />
        <Route path="/survey" element={<FieldSurvey />} />
        <Route path="/addfamily" element={<AddFamily />} />
        <Route path="/addmember" element={<AddMember />} />
      </Routes>
      {
        auth.isLoading || survey.isLoading
        ?
        <FullScreenLoading />
        :
        null
      }
    </Router>
  )
}

export default RootRouter;