import styled from 'styled-components';
import {animated} from 'react-spring';
import {SecondaryButton} from 'components';

export const RootContainer = styled.div`
	position: relative;
	padding: 0px 10px;
	width: 100vw;
	min-height: 100vh;
	overflow: scroll;
	background-color: ${({theme}) => theme.colors.baseBackground};
	box-sizing: border-box;
	overflow: auto;
`;


export const Header = styled.div`
	position: relative;
	height: 165px;
	padding: 10px 20px;
	font-size: ${({theme}) => theme.font.sizes.heading};
	border-radius: 10px;
	border: none;
	outline: none;
	background: ${({theme}) => theme.colors.containerBackground};
`;

export const AddStreetContainer = styled(Header)`
	width: 75%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const HeaderChoice = styled.span`
	color: ${({theme, selected}) => selected?theme.colors.halfWhite:theme.colors.lightGrey};
	font-size: ${({theme, selected}) => selected?theme.font.sizes.heading:theme.font.sizes.subHeading};
	font-weight: ${({theme, selected}) => selected?theme.font.weight.bold:theme.font.weight.normal};
	border: none;
	outline: none;
	cursor: pointer;
	border-bottom: 2px solid #ccc;
	margin: 5px;
	padding: 10px;
`;
export const AddressSelectedContainer = styled(animated.div)`
	position: absolute;
	width: calc(100% - 40px);

`;
export const AddressSelected = styled.div`
	width: 100%;
	height: 35px;
	border: none;
	margin: 10px 0;
	margin-top: 20px;
	padding: 10px 20px;
	border-radius: 10px;
	outline: none;
	overflow: hidden;
	color: white;
	background: ${({theme}) => theme.colors.baseBackground};
	box-sizing: border-box;

`;
export const TextInputLabel = styled.input.attrs({
	type: 'text',
	placeholder: 'Search with Mobile Number*'
})`
	font-size: ${({theme}) => theme.font.sizes.subHeading};
	line-height: ${({theme}) => theme.font.lineHeight(theme.font.sizes.normal)};
	font-weight: ${({theme}) => theme.font.weight.normal};
	color: ${({theme}) => theme.colors.white};

	width: 100%;
	height: 35px;
	border: 1px solid #ccc;
	margin: 10px 0;
	margin-top: 20px;
	padding: 10px 20px;
	border-radius: 10px;
	outline: none;
	overflow: hidden;
	color: white;
	background: ${({theme}) => theme.colors.baseBackground};
	box-sizing: border-box;
`;
export const FamilyScroller = styled.div`
	// margin-top: 20px;
	height: calc(100vh - 250px);
	overflow-y: scroll;
`;
export const Family = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: 100px;
	width: 100%;
	padding: 10px 20px;
	margin: 10px 0;
	border: none;
	border-radius: 10px;
	outline: none;
	background: ${({theme}) => theme.colors.containerBackground};
	overflow: hidden;
	box-sizing: border-box;
`;
export const FamilyImage = styled.img.attrs(props => ({
	src: props.src,
	alt: 'family-image'
}))`
	border: none;
	border-radius: 50%;
	height: 80px;
	width: 80px;
	margin-right: 10px;
`;
export const FamilyInfoContainer = styled.div`
	color: ${({theme}) => theme.colors.white};
`;
export const FamilyName = styled.div`
	font-size: ${({theme}) => theme.font.sizes.heading};
	font-weight: ${({theme}) => theme.font.weight.bold};
`;
export const FamilyInfo = styled.div`
	font-size: ${({theme}) => theme.font.sizes.subHeading};
`;
export const AddFamilyBtn = styled(SecondaryButton)`
	position: fixed;
	bottom: 20px;
	left: 0;
	right: 0;
	width: 150px;
	margin: 0 auto;
	background-color: ${({theme}) => theme.colors.green};
`;
export const FamilySubContainer = styled(Family)`
	
`;