import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyADk9ug4XSmitxPOg6_iG721hIlY6LTFxI",
  authDomain: "tnphcframework.firebaseapp.com",
  projectId: "tnphcframework",
  storageBucket: "tnphcframework.appspot.com",
  messagingSenderId: "761895488977",
  appId: "1:761895488977:web:a46ec4f6112e6ca787a4f0",
  measurementId: "G-RJCZH25FVL"
};

initializeApp(firebaseConfig);