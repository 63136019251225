import {call, put, select, takeLeading} from 'redux-saga/effects';
import {
	searchWithMobileAsyncActions,
	loginAsyncActions,
} from './actions';
import {
	login
} from './api.js';
import {toast} from 'react-toastify';
import i18next from 'i18next';

function* loginSaga(action) {
	try {
		const {mobile} = action.payload
		const {data} = yield call(login, mobile)

		yield put(loginAsyncActions.success(data.result))
	}
	catch(err) {
		if(err?.response?.data?.custom)
			toast.error(i18next.t(err.response.data.message))
		else
			toast.error(i18next.t('error.somethingWentWrong'))
		console.log("error", err.response)
		yield put(loginAsyncActions.error())
	}
}

// function* searchWithMobileSaga(action) {
// 	try {
// 		let families;
// 		const querySnapshot = yield put(searchWithMobile, action.payload)
// 		console.log("QUERY SNAPSHOT", querySnapshot);
// 		querySnapshot.forEach((doc) => {
// 			console.log(doc.id, " => ", doc.data());
// 			families = [...families, {...doc.data(), id: doc.id}]
// 		});
// 		yield put(searchWithMobileAsyncActions.success(families))
// 	}
// 	catch(err) {
// 		console.log("saga error", err)
// 		yield put(searchWithMobileAsyncActions.error(err))
// 	}
// }

function* mainSaga() {
	// yield takeLeading(searchWithMobileAsyncActions.actionNames.request, searchWithMobileSaga)
	yield takeLeading(loginAsyncActions.actionNames.request, loginSaga)
}

export default mainSaga;