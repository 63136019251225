import {
	getVillagesAsyncActions,
	getFamiliesAsyncActions,
	getFamiliesByMobileAsyncActions,
	createNewStreetAsyncActions,
	createNewFamilyAsyncActions,
	addNewMemberAsyncActions,
	saveRecordAsyncActions,
} from './actions';

const initialState = {
	villages: [],
	families: [],
	currentSelectedFamily: '',
	surveys: [],
	prescriptions: [],
	isLoading: false,
	isError: false,
	error: '',
}

export default (state = initialState, action) => {
	// console.log("action payload", action)
	switch(action.type) {
		case getVillagesAsyncActions.actionNames.request:
			return {...state, isLoading: true, villages: []}
		case getVillagesAsyncActions.actionNames.success:
			return {...state, isLoading: false, villages: action.payload}
		case getVillagesAsyncActions.actionNames.error:
			return {...state, isLoading: false, isError: true, error: action.payload, villages: []}

		case getFamiliesByMobileAsyncActions.actionNames.request:
			return {...state, isLoading: true, families: []}
		case getFamiliesByMobileAsyncActions.actionNames.success:
			return {...state, isLoading: false, families: action.payload}
		case getFamiliesByMobileAsyncActions.actionNames.error:
			return {...state, isLoading: false, isError: true, error: action.payload, families: []}

		case createNewStreetAsyncActions.actionNames.request:
			return {...state, isLoading: true}
		case createNewStreetAsyncActions.actionNames.success:
			return {...state, isLoading: false}
		case createNewStreetAsyncActions.actionNames.error:
			return {...state, isLoading: false, isError: true, error: action.payload}

		case saveRecordAsyncActions.actionNames.request:
			return {...state, isLoading: true}
		case saveRecordAsyncActions.actionNames.success:
			return {...state, isLoading: false}
		case saveRecordAsyncActions.actionNames.error:
			return {...state, isLoading: false, isError: true, error: action.payload}

		default:
			return {...state}
	}
}