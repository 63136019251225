import {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import {
	RootContainer,
	CaptchaContainer,
	MobileContainer,
	Heading,
	LanguageSelector,
	LanguageText,
	ContainerTitle,
} from './Auth.styles'
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {authActions} from 'modules/auth';
import {VerticalSpace, HorizontalSpace, SecondaryButton, UnderlinedButton, TextInput, CodeInput, FullScreenLoading} from 'components';
import Switch from 'react-switch';
import { getAuth, signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import {toast} from 'react-toastify';

const Auth = () => {
	const {t, i18n} = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const authState = useSelector(state => state.auth)
	const [loading, setLoading] = useState(false)
	const [language, setLanguage] = useState(false)
	const [confirmation, setConfirmation] = useState(null)
	const [mobile, setMobile] = useState('')
	const [otp, setOtp] = useState('')
	const auth = getAuth();

	const onVerify = () => {
		setLoading(true)
		if(!confirmation) {
			signInWithPhoneNumber(auth, `+91${mobile}`, window.recaptchaVerifier)
		    .then((confirmationResult) => {
		    	setLoading(false)
		    	// console.log("ConfirmResult")
		      	setConfirmation(confirmationResult);
		    }).catch((error) => {
		    	setLoading(false)
		    	// console.log("ERROR", error)
		    	toast.error("Error sending Verification SMS to your Mobile")
		    });
		    return null;
		}
		confirmation.confirm(otp).then((result) => {
			setLoading(false)
			const user = result.user;
		  dispatch(authActions.loginAsyncActions.request({mobile: `91${mobile}`}))
		}).catch((error) => {
			setLoading(false)
			// console.log("OTP ERROR", error)
			toast.error("Error verifying OTP. Please check yout OTP")
		});
	}

	useEffect(() => {
		window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
		  'size': 'invisible',
		  'callback': (response, error) => {
		    // reCAPTCHA solved, allow signInWithPhoneNumber.
		    // console.log("RECAPTCHA", response)
		    // console.log("RECAPTCHA ERROR", error)
		  }
		}, auth);
	}, [])

	useEffect(() => {
		if(language) {
			i18n.changeLanguage('ta');
		}
		else {
			i18n.changeLanguage('en');
		}
	}, [language])

	useEffect(() => {
		if(authState.isAuthenticated)
			navigate('/survey')
	}, [authState.isAuthenticated])

	// console.log("IS AUTHENTICATED", authState)

	return (
		<RootContainer>
			<CaptchaContainer id='sign-in-button' />
			<MobileContainer>
				<Heading>{t('auth.headerTitle')}</Heading>
				<VerticalSpace count={2} />
				<LanguageSelector>
					<LanguageText selected={language === false} >English</LanguageText>
					<HorizontalSpace count={1} />
					<Switch onChange={setLanguage} checked={language} uncheckedIcon={false} checkedIcon={false} />
					<HorizontalSpace count={1} />
					<LanguageText selected={language === true} >தமிழ்</LanguageText>
				</LanguageSelector>
				<VerticalSpace count={5} />
				<ContainerTitle>{t('auth.signinTitle')}</ContainerTitle>
				<VerticalSpace count={5} />
				{
					!confirmation
					?
					<TextInput size={15} maxLength={10} value={mobile} placeholder={t('auth.emailPlaceHolder')} onChange={evt => setMobile(evt.target.value)} />
					:
					<CodeInput size={12} maxLength={6} value={otp} placeholder={t('auth.otpPlaceHolder')} onChange={evt => setOtp(evt.target.value)} />
				}
				<VerticalSpace count={3} />
				<SecondaryButton style={{width: '110px'}} onClick={onVerify} >{t('auth.submitButton')}</SecondaryButton>
				<VerticalSpace count={2} />
				{
					confirmation
					?
					<UnderlinedButton onClick={() => {setConfirmation(null)}} >Go Back</UnderlinedButton>
					:
					null
				}
			</MobileContainer>
			{
				loading
				?
				<FullScreenLoading />
				:
				null
			}
		</RootContainer>
	)
}

export default Auth;