import {call, put, select, takeLeading} from 'redux-saga/effects';
import {
	getVillagesAsyncActions,
	getFamiliesAsyncActions,
	getFamiliesByMobileAsyncActions,
	createNewStreetAsyncActions,
	createNewFamilyAsyncActions,
	addNewMemberAsyncActions,
	saveRecordAsyncActions,
} from './actions';
import {
	getVillages,
	getFamilies,
	getFamiliesByMobile,
	createNewStreet,
	createNewFamily,
	addNewMember,
	saveRecord,
} from './api.js';
import {toast} from 'react-toastify';
import i18next from 'i18next';

function* getVillagesSaga(action) {
	try {
		const {id} = action.payload
		const {data} = yield call(getVillages, id)
		// console.log("DATA RESULT");
		yield put(getVillagesAsyncActions.success(data.result))
	}
	catch(err) {
		if(err?.response?.data?.custom)
			toast.error(i18next.t(err.response.data.message))
		else
			toast.error(i18next.t('error.somethingWentWrong'))
		// console.log("error", err.response)
		yield put(getVillagesAsyncActions.error())
	}
}

function* createNewStreetSaga(action) {
	try {
		const id = yield select(state => state.auth.user.associatedInstitution)
		const {data} = yield call(createNewStreet, action.payload)
		yield put(createNewStreetAsyncActions.success(data.result))
		yield put(getVillagesAsyncActions.request({id}))
	}
	catch(err) {
		if(err?.response?.data?.custom)
			toast.error(i18next.t(err.response.data.message))
		else
			toast.error(i18next.t('error.somethingWentWrong'))
		// console.log("error", err.response)
		yield put(getVillagesAsyncActions.error())
	}
}

function* getFamiliesByMobileSaga(action) {
	try {
		const {mobile} = action.payload
		const {data} = yield call(getFamiliesByMobile, mobile)
		// console.log("DATA RESULT");
		yield put(getFamiliesByMobileAsyncActions.success(data.result))
	}
	catch(err) {
		if(err?.response?.data?.custom)
			toast.error(i18next.t(err.response.data.message))
		else
			toast.error(i18next.t('error.somethingWentWrong'))
		// console.log("error", err.response)
		yield put(getFamiliesByMobileAsyncActions.error())
	}
}

function* createNewFamilySaga(action) {
	try {
		const family = action.payload
		const primaryCentreId = yield select(state => state.auth.user.associatedInstitution._id)
		const {data} = yield call(createNewFamily, family)
		// console.log("DATA RESULT");
		yield put(createNewFamilyAsyncActions.success(data.result))
		yield put(getVillagesAsyncActions.request({id: primaryCentreId}))
		action.payload.callback()
	}
	catch(err) {
		if(err?.response?.data?.custom)
			toast.error(i18next.t(err.response.data.message))
		else
			toast.error(i18next.t('error.somethingWentWrong'))
		// console.log("error", err.response)
		yield put(createNewFamilyAsyncActions.error())
	}
}

function* addNewMemberSaga(action) {
	try {
		const individual = action.payload
		const primaryCentreId = yield select(state => state.auth.user.associatedInstitution._id)
		
		const {data} = yield call(addNewMember, individual)
		yield put(addNewMemberAsyncActions.success(data.result))
		yield put(getVillagesAsyncActions.request({id: primaryCentreId}))
		action.payload.callback()
	}
	catch(err) {
		if(err?.response?.data?.custom)
			toast.error(i18next.t(err.response.data.message))
		else
			toast.error(i18next.t('error.somethingWentWrong'))
		// console.log("error", err.response)
		yield put(createNewFamilyAsyncActions.error())
	}
}

function* saveRecordSaga(action) {
	try {
		const primaryCentreId = yield select(state => state.auth.user.associatedInstitution._id)
		const {data} = yield call(saveRecord, action.payload)
		// console.log("SAVE DATA RESULT");
		yield put(saveRecordAsyncActions.success(data.result))
		action.payload.callback()
	}
	catch(err) {
		if(err?.response?.data?.custom)
			toast.error(i18next.t(err.response.data.message))
		else
			toast.error(i18next.t('error.somethingWentWrong'))
		// console.log("error", err.response)
		yield put(saveRecordAsyncActions.error())
	}
}

function* mainSaga() {
	yield takeLeading(getVillagesAsyncActions.actionNames.request, getVillagesSaga)
	yield takeLeading(createNewStreetAsyncActions.actionNames.request, createNewStreetSaga)
	yield takeLeading(getFamiliesByMobileAsyncActions.actionNames.request, getFamiliesByMobileSaga)
	yield takeLeading(createNewFamilyAsyncActions.actionNames.request, createNewFamilySaga)
	yield takeLeading(addNewMemberAsyncActions.actionNames.request, addNewMemberSaga)
	yield takeLeading(saveRecordAsyncActions.actionNames.request, saveRecordSaga)
}

export default mainSaga;