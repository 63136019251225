const sizes = {
	normal: "1.4rem",
	heading: "1.8rem",
	subHeading: "1.6rem"
}

const weight = {
	normal: 400,
	bold: 700,
}

const lineHeight = (fontSize) => {
	return `${(parseFloat(fontSize)*10)*1.4}px`;
}

export default {sizes, weight, lineHeight};

