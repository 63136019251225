const colors = {
	white: "#F7F7FF",
	black: "#111111",
	darkGrey: "#333",
	lightGrey: "#555",
	blue: "#0096C7",
	halfWhite: "#EAEBEB",
	yellow: "#EB9C37",
	red: "#E14847",
	green: "#46C292",
	disabledBackground: "#E5E5E5",
	disabledText: "#C4C4C4",
	baseBackground: "#191A1F",
	containerBackground: "#121318"
}

export default colors;