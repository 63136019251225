export default {
	translation: {	
		auth: {
			headerTitle: 'தமிழ்நாடு பொது சுகாதாரப் பதிவேடு',
			signinTitle: 'OTP கொண்டு  உள்நுழையவும்',
			pinTitle: 'பின் நம்பர் கொண்டு உள்நுழையவும்',
			emailPlaceHolder: 'மொபைல் எண்*',
			otpPlaceHolder: 'OTP ஐ உள்ளிடவும்',
			submitButton: 'சரிபார்க்கவும்'
		},
		survey: {
			villagesDropdownTitle: "கிராமம்*",
			streetsDropdownTitle: "தெரு*",
			familyTitle: "'யின் குடும்பம்",
			address: "முகவரி",
			mobile: "மொபைல்",
			selectVillage: "தெருக்களைப் பார்க்க ஒரு கிராமத்தைத் தேர்ந்தெடுக்கவும்!",
			createNew: "+ புதிதாக உருவாக்கவும்",
			familyMembers: "குடும்ப உறுப்பினர்கள்",
			addMemberSuccessful: "புதிய உறுப்பினர் சேர்க்கப்பட்டார்",
			allFieldsAreMandatory: "நீங்கள் அனைத்து புலங்களையும் நிரப்ப வேண்டும்",
			doorNumber: "கதவு எண்*",
			name: "பெயர்*",
			age: "வயது*",
			gender: "பாலினம்",
			bloodGroup: "இரத்த வகை*",
			proofType: "அடையாள அட்டையின் பெயர்*",
			proofId: "அடையாள அட்டை எண்*",
			maleShort: "ஆ",
			femaleShort: "பெ",
			male: "ஆண்",
			female: "பெண்",
			dateOfBirth: "பிறந்த தேதி*",
			day: "தேதி*",
			month: "மாதம்*",
			year: "ஆண்டு*",
			bloodPressure: "இரத்த அழுத்தம்",
			systole: "சிஸ்டாலிக்",
			diastole: "டயஸ்டாலிக்",
			sugar: "இரத்த சர்க்கரை அளவு",
			aadhar: "ஆதார்",
			voterId: "வாக்காளர் அட்டை",
			rationCard: "குடும்ப அட்டை",
			drivinglisence: "ஓட்டுநர் உரிமம்",
			other: "மற்றவை",
			addAnotherMember: "மற்றொரு உறுப்பினரைச் சேர்க்கவும்",
			saveNewFamily: "புதிய குடும்பத்தைச் சேர்க்கவும்",
			addNewFamily: "புதிய குடும்பத்தைச் சேர்க்கவும்",
			saveNewMember: "புதிய உறுப்பினரை சேர்க்கவும்",
			exit: "வெளியேறு",
			saveAndNext: "பதிவிட்டு அடுத்து செல்லவும்",
			startSurvey: "பதிவு செய்யத் தொடங்கவும்",
			addFamilyMember: "புதிய உறுப்பினரைச் சேர்க்கவும்",
			members: "உறுப்பினர்கள்",
			pastIllness: "கடந்த கால நோய்கள்",
			hypertension: "உயர் இரத்த அழுத்தம்",
			gdm: "கர்ப்பகால நீரிழிவு நோய்",
			familyHistory: "பரம்பரை நோய்கள்",
			diabettis: "நீரிழிவு நோய்",
			diagnosedWith: "கண்டறியப்பட்ட நோய்கள்",
			years: "வயது",
			medicinesGiven: "மருந்துகள் வழங்கப்பட்டன",
		},
		error: {
			somethingWentWrong: "ஏதோ தவறு நடைபெற்றிருக்கிறது",
			nothingToShow: "இங்கே காண்பிக்க எதுவும் இல்லை",
			login: {
				userNotFound: "இந்த மொபைல் எண்ணுக்கான பயனரைக் கண்டறிய முடியவில்லை. உங்கள் தொடர்புடைய PHC உடன் சரிபார்க்கவும்"
			},
			findFamily: {
				notfound: "இந்த மொபைல் எண்ணுடன் இணைக்கப்பட்ட குடும்பத்தைக் கண்டறிய முடியவில்லை"
			}
		},
		featureComingSoon: "இந்த அம்சம் வளர்ச்சியில் உள்ளது. இந்த அம்சத்தை இறுதி திட்ட டெமோவில் காண்பிப்போம். நன்றி!"
	}
}