import styled from 'styled-components';

export const TextInput = styled.input.attrs(({type}) => ({type: type || 'text'}))`
	width: 250px;
	border: none;
	outline: none;
	padding: 5px 10px;
	border-radius: 5px;
	border-radius: 15px;
	font-size: ${({theme}) => theme.font.sizes.subHeading};
	font-weight: ${({theme}) => theme.font.weight.bold};
	line-height: ${({theme}) => theme.font.lineHeight(theme.font.sizes.subHeading)};
`;

export const SizedTextInput = styled(TextInput)`
	width: ${({width}) => width};
`;

export const CodeInput = styled(TextInput)`
	font-size: ${({theme}) => theme.font.sizes.heading};
	font-weight: ${({theme}) => theme.font.weight.bold};
	line-height: ${({theme}) => theme.font.lineHeight(theme.font.sizes.heading)};
	letter-spacing: 10px;
	text-align: center;

	&::placeholder {
		letter-spacing: 3px;
	}
`;

export {default as TextInputWithLoader} from './InputWithLoader';