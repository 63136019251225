import {combineReducers} from 'redux';
import {all, fork} from 'redux-saga/effects';
import auth, {authSaga} from './auth';
import survey, {surveySaga} from './survey';
import { getFirestore } from "firebase/firestore";

export const db = getFirestore();

export function* rootSaga() {
  yield all([
    authSaga(),
    surveySaga()
  ])
}

export default combineReducers({
  auth,
  survey
})